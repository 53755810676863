<template>
    <div>
        <div class="container-body" v-if="ClientWidth_number > 850">

            <div class="main-content" v-if="mouse_hover_index === 0">
                <p class="c-white p-bottom-20 fs20">安全检测</p>
                <div class="main-box">
                    <img src="../../assets/img3.png" style="width: 120%; height: 120%; border-radius: 5px"/>
                    <div class="text fs14 c-white">
                        对单位各个重点门户网站、核心网络、数据库、重要业务系统和云系统等进行的以三级等保为标准的安全检测
                    </div>
                </div>
            </div>

            <div class="main-content" v-if="mouse_hover_index === 1">
                <p class="c-white p-bottom-20 fs20">威胁管控</p>
                <div class="main-box">
                    <img src="../../assets/img5.png" style="width: 120%; height: 120%; border-radius: 5px"/>
                    <div class="text fs14 c-white">
                        威胁管控包括赛尔AD防护系统和赛尔安全态势感知两款产品
                    </div>
                </div>
            </div>

            <div class="main-content" v-if="mouse_hover_index === 2">
                <p class="c-white p-bottom-20 fs20">安全咨询</p>
                <div class="main-box">
                    <img src="../../assets/img1.jpg" style="width: 120%; height: 120%; border-radius: 5px"/>
                    <div class="text fs14 c-white">
                        赛尔安全咨询服务专业团队和资深专家依托全面的知识体系和丰富的行业经验，依据国际/国内标准和行业监管规范，协助各行业客户立足于实际现状，面向信息安全风险，采取适当的管理过程和控制措施，建立和维护全面、有效、合规的信息安全管理体系，整体提升信息安全管理成熟度，保障业务运营和战略达成
                    </div>
                </div>
            </div>
            <div class="main-content" v-if="mouse_hover_index === 3">
                <p class="c-white p-bottom-20 fs20">安全防护</p>
                <div class="main-box">
                    <img src="../../assets/img3.png" style="width: 120%; height: 120%; border-radius: 5px"/>
                    <div class="text fs14 c-white">
                        对检测出问题的并且需要重点防护的网络进行安全防护，提供软硬件产品和技术解决方案。
                    </div>
                </div>
            </div>

            <div class="main-content" v-if="mouse_hover_index === 4">
                <p class="c-white p-bottom-20 fs20">安全众测</p>
                <div class="main-box">
                    <img src="../../assets/img4.png" style="width: 120%; height: 120%; border-radius: 5px" alt=""/>
                    <div class="text fs14 c-white">
                        借助众多白帽子和自建专业技术团队力量针测试目标系统在规定时间内利用多种技术手段或漏扫设备进行漏洞悬赏式竞技检测，收到有效的漏洞后按漏洞风险等级给予一定的奖励，按漏洞付费。
                    </div>
                </div>
            </div>

            <div class="main-content" v-if="mouse_hover_index === 5">
                <p class="c-white p-bottom-20 fs20">资产管理</p>
                <div class="main-box">
                    <img src="../../assets/img6.png" style="width: 120%; height: 120%; border-radius: 5px" alt=""/>
                    <div class="text fs14 c-white">
                        资产管理是一套互联网资产测绘和风险评估系统，采用网络全栈爬虫、高精度设备指纹识别、自适应智能调度算法、高危POC（Proof of
                        Concept）漏洞自动化验证等关键技术，对网络空间资产进行四层建模（硬件设备层、操作系统层、服务支撑层、应用场景层），对资产高危漏洞进行专扫，帮助用户完成互联网暴露面资产摸底排查、高危风险识别、网络空间测绘，为网络安全态势感知、研判、处置提供基础支撑。
                    </div>
                </div>
            </div>

            <div class="main-content" v-if="mouse_hover_index === 6">
                <p class="c-white p-bottom-20 fs20">实战演习</p>
                <div class="main-box">
                    <img src="../../assets/img7.png" style="width: 120%; height: 120%; border-radius: 5px"/>
                    <div class="text fs14 c-white">
                        实战演习是集“学、练、测、赛”为一体的全方位信息安全攻防演练平台，它的内容涵盖网络安全、操作系统安全、数据库安全、WEB应用安全、移动终端应用安全、无线安全、工控安全等多方向安全技术，广泛用于多行业和大型企事业单位的人才培养和人才选拔，是网络安全教学、实训、竞技实操平台。
                    </div>
                </div>
            </div>

            <div class="body-box" v-if="ClientWidth_number > 850">
                <li :key="index"
                    :style="mouse_hover_index === index ? { color: '#ccc' } : ''"
                    @click="navigateTo(index)"
                    @mouseover="mouseOver(index)"
                    v-for="(item, index) in hover_list">
                    <img :src="mouse_hover_index === index ? require(`../../assets/foot0${index}-active.png`) : require(`../../assets/foot0${index}.png`)" alt=""/>
                    <div href="">{{ item }}</div>
                </li>
            </div>
        </div>

        <div class="container-body-phone" v-else>
            <div class="main-content-phone" style="position: relative;top:30vw;left:5vw;" v-if="mouse_hover_index === 0">
                <p class="c-white p-bottom-20 fs20">公司简介</p>
                <div class="main-box">
                    <img src="../../assets/img1.jpg" style="width: 80%; height: 100%; border-radius: 5px" alt=""/>
                    <div class="text fs14 c-white" style="font-size: 0.8rem">
                        北京赛尔汇力安全科技有限公司（简称：赛尔安全）成立于2007年，专业致力于网络安全、信息安全、信息系统建设等网络安全服务
                        赛尔安全的控股方赛尔网络有限公司是由教育部科技发展中心与清华大学、北京大学等十多所著名高校共同出资兴办的，是中国教育和科研计算机网的运营服务商。
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Home",
        data() {
            return {
                ClientWidth_number: document.documentElement.clientWidth,
                mouse_hover_index: 0,
                hover_list: [
                    '安全检测',
                    '威胁管控',
                    "安全咨询",
                    "安全防护",
                    "安全众测",
                    "资产管理",
                    "实战演习",
                ],
            };
        },
        created() {
            this.getClientWidth();
        },
        methods: {
          navigateTo(index) {

                this.$parent.title_index = -1;

                switch (index) {
                    case 0 :
                        this.$router.push('/vss.html');
                        break;
                    case 1 :
                        this.$router.push('/ad.html');
                        break;
                    case 2 :
                        this.$router.push('/consult.html');
                        break;
                    case 3 :
                        this.$router.push('/protect.html');
                        break;
                    case 4 :
                        this.$router.push('/test.html');
                        break;
                    case 5 :
                        this.$router.push('/management.html');
                        break;
                    case 6 :
                        this.$router.push('/practice.html');
                        break;
                }
            },
            mouseOver(index) {
                this.mouse_hover_index = index;
            },
            getClientWidth() {
                window.onresize = () => {
                    this.ClientWidth_number = document.documentElement.clientWidth;
                };
            },
        },
    };
</script>

<style lang='less' scoped>
    @baseWidth: 1980;
    .pxfix(@w) {
        width: (@w / @baseWidth) * 100vw;
    }

    .container {
        .pxfix(1980);
        min-height: 100vh;
        background: #ccc;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .phone_menu-box {
            .pxfix(1700);
            background: #fff;
            margin: 0 auto;
            box-shadow: 10px 10px 5px #888888;

            li {
                font-size: 12px;
                border: 1px solid #ebebeb;
                padding: 10px 0 10px 10px;
            }
        }

        .header {
            height: 75px;
            background: #fff;

            .header-box {
                .pxfix(1700);
                height: 75px;
                margin: 0 auto;
                display: flex;
                color: #4b77be;
                text-decoration: none;
                font-weight: bolder;
                font-size: 1.3rem;
                justify-content: space-between;
                align-items: center;
            }
        }

        .container-menu {
            .pxfix(1980);
            height: 51px;
            background: #333;
            display: flex;
            justify-content: center;

            .menu-box {
                min-width: 60.7vw;
                height: 51px;
                display: flex;
                align-items: center;

                li {
                    margin-left: 10px;
                    font-size: 0.8rem;
                    font-weight: bolder;
                    height: 51px;
                    padding: 0 10px;
                    line-height: 51px;
                    color: gray;
                    cursor: pointer;
                }
            }
        }

        .phone_body {
            .pxfix(1980);
            height: 500px;
            position: relative;
            flex: 1;
            margin: 0 auto;
            background: #fff;
            background: url(../../assets/phone-index-bg.jpg);

            .main-content {
                .pxfix(1580);
                height: 300px;
                // position: absolute;
                // left: 50%;
                // top: 50%;
                margin: 20% auto;
                border-radius: 5px;
            }

            .c-white {
                color: white;
            }

            .fs20 {
                font-size: 20px;
            }

            .p-bottom-20 {
                // padding-bottom: 20px;
                margin-bottom: 15px;
                margin-left: 10px;
            }

            .main-box {
                position: relative;
                width: 450px;
                height: 250px;
                border-radius: 5px;
                z-index: 2;
            }

            img {
                vertical-align: middle;
            }

            .main-box .text {
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: 100%;
                line-height: 28px;
                z-index: 3;
                background: rgba(0, 106, 182, 0.75);
                border-radius: 5px 0 0 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 27px 0;
            }
        }

        .container-body-phone {
            .pxfix(1980);
            height: 793px;
            margin: 0 auto;
            background: #fff;
            // margin-top: 20px;
            display: flex;
            flex: 1;
            background: url(../../assets/phone-index-bg.jpg);
        }

        .container-body {
            .pxfix(1980);
            height: 793px;
            margin: 0 auto;
            background: #fff;
            // margin-top: 20px;
            display: flex;
            flex: 1;
            background: url(../../assets/index-bg.jpg);

            .main-content {
                width: 480px;
                height: 300px;
                position: absolute;
                top: 300px;
                right: 100px;
                border-radius: 5px;
            }

            .c-white {
                color: white;
            }

            .fs20 {
                font-size: 20px;
            }

            .p-bottom-20 {
                // padding-bottom: 20px;
                margin-left: 10px;
            }

            .main-box {
                position: relative;
                width: 450px;
                height: 250px;
                border-radius: 5px;
                z-index: 2;
            }

            img {
                vertical-align: middle;
            }

            .main-box .text {
                position: absolute;
                top: 20px;
                left: 10px;
                width: 50%;
                height: 100%;
                line-height: 28px;
                z-index: 3;
                background: rgba(0, 106, 182, 0.75);
                border-radius: 5px 0 0 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 15px 27px 36px;
            }

            li {
                display: flex;
                width: 130px;
                height: 50px;
                align-items: center;
            }

            img {
                width: 30px;
                height: 30px;
                margin: 20px 10px;
            }

            .body-box {
                display: flex;
                align-items: flex-end;
                margin: 0 auto;
                // flex-wrap: wrap;

                li {
                    display: flex;
                    width: 11vw;
                    justify-content: center;
                    height: 50px;
                    align-items: center;
                    margin-left: 20px;
                    margin-bottom: 40px;
                    color: gray;

                    img {
                        width: 30px;
                        height: 30px;
                        margin: 20px 10px;
                    }
                }
            }
        }

        .footer {
            line-height: 50px;
            background: #2f3034;
            width: 100%;
            font-size: 12px;
            color: #fff;
            text-align: center;
        }
    }
</style>
